import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "techniques",
    path: "accueil / nos métiers / techniques / Responsable Technique"
  },
  title: "Responsable Technique",
	missions:[
		{
		  text: "Animer et mettre en œuvre la production informatique d’une ou plusieurs équipes" 
		},
		{
		  text: "Garantir la sécurité de l’infrastructure et des réseaux" 
		},
		{
		  text: "Optimiser la prestation afin d’atteindre les engagements de service" 
		},
		{
		  text: "Encadrer et relayer la politique RH de l’entreprise" 
		},
		{
		  text: "Initier et conduire les projets" 
		},
	],
  
	key_skills: [
		{
		  text: "Expression écrite" 
		},
		{
		  text: "Décider" 
		},
		{
		  text: "Communiquer (interne, hiérarchie, client, équipes)" 
		},
		{
		  text: "Se positionner en leader" 
		},
		{
		  text: "Contrôler" 
		},
		{
		  text: "Analyser et comprendre" 
		},
		{
		  text: "Anticiper" 
		},
		{
		  text: "" 
		},
	], 
	prerequisites:[
		{
		  text: "Maitrise" 
		},
		{
		  text: "Capacité à encadrer dans un environnement matriciel" 
		},
		{
		  text: "Anglais opérationnel" 
		},
		{
		  text: "5 ans d’expérience en tant qu’administrateur système réseau confirmé ou équivalent." 
		},
	],
	activities:[
		{
		  text: "Planifier et organiser le travail de l’équipe" 
		},
		{
		  text: "Définir les besoins et organiser les ressources du dispositif de production" 
		},
		{
		  text: "Anticiper et piloter les évolutions du système ou de l’architecture" 
		},
		{
		  text: "Structurer et faire évoluer les processus d’exploitation" 
		},
		{
		  text: "Piloter et suivre les conditions opérationnelles de la production" 
		},
		{
		  text: "Proposer des pistes d’amélioration continue du service" 
		},
		{
		  text: "Encadrer et objectiver les salariés" 
		},
	],	
}
const LocalPage = () => (
  <PagePoste {...pageData}/>
);
export default LocalPage;